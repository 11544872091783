import GET_ARTICLES from "../class/getArticles";
import Swiper from "swiper/bundle";

const [d] = [document];
const domReady = TPL => {
  new BLOCKS;
};

class BLOCKS {
  constructor() {
    this.mainvisual();
    this.news();
  }
  
  mainvisual () {
    let Mv = document.querySelector('.mainvisual');
    window.addEventListener('load', function() {
      Mv.classList.add('loaded');
    });

    //メインスライダー
    const mvSlide = new Swiper('.mv_slide[data-plugin="mainSlider"]',{
      direction: 'vertical',
      loop: true,
      effect: 'fade',
      speed: 1200,
      allowTouchMove: false,
      fadeEffect: {
        crossFade: true, // クロスフェードを有効にする（フェードモードの場合 true 推奨）
      },
      autoplay:{
        delay: 4000,
        disableOnInteraction: false,
        waitForTransition: false
      },
    });

    // news
    const news_ticker = new GET_ARTICLES('.mainvisual .news_wrap[data-plugin="getArticles"]');
    news_ticker.makeItem = (item,content) => `
      <a href="${item.href}">
        <p class="ttl">Topics</p>
        <p class="info"><span class="date">${item.date}</span><span class="cate">${item.category.name}</span></p>
        <p class="article_ttl">${item.title}</p>
      </a>`;
    // news_ticker.makeItem = (item) => console.log(item);
    news_ticker.render();
  }

  news () {
    //NEWS
    const getArticle = new GET_ARTICLES('.block-news .news_list');
    getArticle.makeItem = (item,content) => `
    <li>
      <a${(item.is_new)? ' class="is-new"': ''} href="${item.href}">
        <p class="info"><span class="date">${item.date}</span><span class="cate">${item.category.name}</span></p>
        <p class="ttl">${item.title}</p>
      </a>
    </li>`;
    // getArticle.makeItem = (item) => console.log(item);
    getArticle.render();
  }

}

export default function HOME () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
}