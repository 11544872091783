const [ w, d, mq ] = [window, document, window.matchMedia( "(max-width: 900px)" ) ];

const domReady = () => {
  // Promise.all([window.load['about-business']]).then( () => new businessBLOCK );
  setTimeout(() => {
    new aboutBLOCK;
  }, 500);
};

class aboutBLOCK {
  constructor() {
    this.scrollFade();
  }

  scrollFade () {
    if(!mq.matches){
      $(window).on('load scroll', function(){
        let pointBlock = $('.block-point');
        let pointPt = pointBlock.offset().top;
        let pt2 = pointPt + 500;
        let pt3 = pointPt + 1100;
        let scroll = $(this).scrollTop();
        if(scroll >= pt2 && pt3 > scroll){
          pointBlock.removeClass('pt3');
          pointBlock.addClass('pt2');
        } else if (scroll >= pt3){
          pointBlock.addClass('pt3');
        } else {
          pointBlock.removeClass('pt2');
          pointBlock.removeClass('pt3');
        }
        if(scroll >= pointPt){
          pointBlock.addClass('active');
        }
      });
    }
  }
}

export default function ABOUT () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  // d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
  $(w).on('load', domReady);
  
}