import RWD002 from "../class/rwd002";
import customDrawerNav from "./drawerNav";
import customFormConfirm from "./formConfirm";
import customSubNav from "./subNav";
import LazyDisp from "../class/lazydisp";

const [w, d, mq] = [window, document, window.matchMedia( "(max-width: 900px)" )];

export default class CUSTOMIZE extends RWD002 {

  constructor(opt) {
    opt = Object.assign({
      // fixNavを指定すると、スクロール時に固定するクラスを付与する
      // fixNav : '[data-role="fixNav"]',
    },opt);

    // 上位クラスのコンストラクタ呼び出し
    super(opt);

    // カスタム用drawerNavのインスタンス化
    this.drawerNav = new customDrawerNav(this.opt.drawerNav);

    // カスタム用サブナビ登録クラスのインスタンス化
    this.Subnav = new customSubNav(this.opt);

    // コメントフォーム用確認画面モーダルのインスタンス化
    this.formConfirm = new customFormConfirm(this.opt.formConfirm);

    this.customInit();
  }

  customInit() {

    // イベント一覧
    d.addEventListener('rwd002.beforeInit', () => false );
    d.addEventListener('rwd002.beforeDomready', () => false );
    d.addEventListener('rwd002.beforeWindowload', () => false );
    d.addEventListener('rwd002.zip2addr', () => false );
    d.addEventListener('drawerNav.beforeInit', () => false );
    d.addEventListener('collapseNav.beforeInit', () => false );
    d.addEventListener('Scroll.beforeInit', () => false );
    d.addEventListener('Scroll.scrollUp', () => false );
    d.addEventListener('Scroll.scrollDown', () => false );
    d.addEventListener('Lazydisp.beforeInit', () => false );

    // テンプレートインスタンスのプロミス
    this.promises.init.then( () => false );
    this.promises.domReady.then( () => false );
    this.promises.windowLoad.then( () => false );

    // テンプレートインスタンスの全非同期メソッドの完了時に実行
    this.promiseAll().then( () => false );

    $(() => {
      // htmlデータ読み込み
      $('[data-load-html]').loadHtml();

      // カレントメニュー
      $('[data-plugin=currentMenu]').currentMenu({mode:0,default:999});

      Promise.all([window.load['loadHtml']]).then(() => this.hashscroll());
      Promise.all([window.load['loadHtml']]).then(() => new LazyDisp('[data-lazydisp]'));
    });

    $(window).on('load scroll', function(){
      let scroll = $(this).scrollTop();
      let windowH = $(this).height();
      if(scroll > windowH){
        $('.fixed_link').addClass('active');
      } else {
        $('.fixed_link').removeClass('active');
      }
      // let footPt = $('footer').offset().top;
      // if(mq.matches){
      //   if(scroll > windowH){
      //     $('.fixed_link').addClass('active');
      //   } else {
      //     $('.fixed_link').removeClass('active');
      //   }
      // } else {
      //   if(scroll > windowH && footPt > scroll + windowH){
      //     $('.fixed_link').addClass('active');
      //   } else {
      //     $('.fixed_link').removeClass('active');
      //   }
      // }
    });
  }

  loading() {
    d.documentElement.classList.add( this.opt.loadingClass );
    d.documentElement.classList.add( 'is-pageload-available' );
    d.addEventListener('DOMContentLoaded', () => {
      d.documentElement.classList.remove( this.opt.loadingClass );
      d.documentElement.style.opacity = '';
    });
  }
  
}

window.WebFontConfig = {
  google: {
    families: ['Mulish:600,700', 'Jost:300,500,600,700', 'Roboto:400,500', 'Cantarell:700']
  },
  active: function () {
    sessionStorage.fonts = true;
  }
};

(function () {
  var wf = document.createElement('script');
  wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js';
  wf.type = 'text/javascript';
  wf.async = 'true';
  var s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(wf, s);
})();